.article-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 2rem;
    min-height: 100vh;
}

.article-container .header {
    margin-top: .5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.article-container .menu-list {
    padding-left: 1rem;

    .menu {
        padding: .4rem .5rem;
        border-radius: 4px;
        border: 0;
        background-color: none;

        .menu-icon {
            font-size: 1.5rem;
        }
    }

    .menu:hover {
        cursor: pointer;
        background-color: lightgrey;
    }
}

.article-container .navigation-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.article-container .navigation-button-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    width: fit-content;
    background-color: #EFEFEF;
}

.article-container .navigation-button-container button:hover {
    background-color: lightgrey;
    cursor: pointer;
}

.article-container .navigation-button-container button:hover:disabled {
    background-color: #EFEFEF;
    cursor: default;
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    gap: .5rem;
}

.article-content {
    max-width: 96vw;
    padding: 1rem;
    margin: 0 4rem;
    border: 1px solid #ddd;
    border-radius: 8px;

    ul {
        list-style-type: disc;
        padding: 0 2rem;
    }

    h1 {
        margin-bottom: 1rem;
        padding-bottom: .5rem;
        border-bottom: 1px solid #ddd;
    }

    h2 {
        margin: 1rem 0;
        padding-bottom: .5rem;
        border-bottom: 1px solid #ddd;
    }
}

@media screen and (max-width: 768px) {
    .article-content {
        margin: 0 1rem;
        border: none;
    }

}