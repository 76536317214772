.content-locker {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 250px;
  }
  
  .content {
    padding: 20px;
    background-color: white;
  }
  
  .locked-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    text-align: center;
    padding: 20px;
  }
  
  .locked-message {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .inline-content {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between items */
  }
  
  .inline-content img {
    width: 2rem; /* Adjust width as needed */
    height: auto;
  }
  
  .inline-content h4 {
    margin: 0;
    font-size: 32px;
  }
  
  .locked-message p {
    font-size: 18px;
    margin: 15px 0;
  }
  
  .locked-message button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .locked-message button:hover {
    background-color: #0056b3;
  }
  