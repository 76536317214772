.data-labeling-page {
    padding: 20px;
    background-color: #f8f9fa;
}

.data-labeling-page.header-tab {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.data-labeling-page.header-tab.title {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 18px;
    color: #007bff;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.data-labeling-page.header-tab.title.active {
    background-color: #007bff;
    color: white;
}

.data-labeling-page.header-tab.title:not(.active):hover {
    background-color: #e9ecef;
}

.card {
    margin-top: 20px;
}

.proposal-textarea {
    height: 50vh;
    resize: none;
}
