.code-block {
    position: relative;
    margin: 16px 0;
  }
  
  .copy-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: .75rem;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    color: #cccccc;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    transition: color 0.3s;
    z-index: 1;
  }
  
  .copy-button:hover {
    color: white;
  }
  
  .copy-button:active {
    color: green;
  }
  
  .copy-button.copied {
    color: green;
  }
  