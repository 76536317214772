.custom-scrollable-menu {
    max-height: 300px;
    /* Adjust the height as needed */
    overflow-y: auto;
    /* Adds vertical scroll when content overflows */
}

/* .dropdown-type-history .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
} */