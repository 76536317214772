.confirm-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 50px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.thankyou-image {
  width: 400px;
  /* height: 100px; */
  /* margin: 0 auto; */
}