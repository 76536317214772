.article-box {
  max-height: 400px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  /* Ensure it fills the grid area */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

}

.article-box:hover {
  cursor: pointer;
  float: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.article-header {
  height: 100px;
}

.article-header h3 {
  color: #888;
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
}

.article-header h2 {
  font-size: 18px;
  margin: 10px 0;
}

.article-level {
  color: green;
  font-size: 14px;
  margin-top: .5rem;
}

.article-description {
  font-size: 14px;
  margin: 10px 0;
  height: 150px;
  max-width: 100%;
  display: -webkit-box;
  /* Enables multi-line clamping */
  -webkit-box-orient: vertical;
  /* Sets the container's orientation to vertical */
  -webkit-line-clamp: 5;
  /* Limits to 3 lines */
  overflow: hidden;
  /* Ensures content is hidden beyond the set limit */
  max-height: 7.5em;
  /* Calculate based on the line height */
  line-height: 1.5em;
  /* Set line height explicitly */
}

.article-author {
  display: flex;
  align-items: center;
  margin: 10px 0;
  flex: 1;
}

.author-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.author-name {
  font-weight: bold;
}

.author-title {
  font-size: 12px;
  color: #888;
  margin-left: 5px;
}

.article-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.time {
  font-size: 14px;
  color: #555;
}

.start-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

.start-button:hover {
  background-color: #0056b3;
}