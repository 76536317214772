.home {
  max-width: 1200px;
  padding-top: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* App.css */
.container-summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.left-col {
  order: 1;
}

.right-col {
  order: 2;
}

.button-summary {
  display: inline-block;
  width: 45px;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center;
  border-radius: 5px;
  margin: 5px;
  font-weight: 600;
  cursor: pointer;
  color: #232A31;
}

.button-summary.active {
  background-color: #e7f5ff;
  color: #1967d2;
}

.button-summary:hover {
  /* background-color: #e7f5ff; */
  color: #1967d2;
}

.button-chart {
  display: inline-block;
  padding: 5px 5px;
  border-radius: 5px;
  margin: 0px 1px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  color: #232A31;
}

.button-chart.active {
  background-color: #5b636a;
  color: white;
}

.button-chart:hover {
  /* background-color: #5b636a; */
  /* color: white; */
  color: #1967d2;
}

/* สำหรับ mobile */
@media (max-width: 768px) {
  .container-summary {
    grid-template-columns: 1fr;
  }

  .left-col {
    order: 2;
  }

  .right-col {
    order: 1;
  }

  .dropdown-type-summary {
    order: 2;
    /* Move dropdown-type-summary to top of right-col on mobile */
    width: 100%;
    /* Set the width to 100% to fit the container */
  }

}

.dropdown-type-chart-type {
  /* color: #1967d2; */
  border: 1px solid #e0e0e0;
  border-radius: 2rem;
  margin-bottom: .5rem;
  order: 1;
  /* Default order */
}

.dropdown-type-chart-type:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .08);
  /* color: #1967d2; */
}

.dropdown-type-chart-type:active {
  background-color: rgba(0, 0, 0, .08);
}

.dropdown-type-chart-type .btn.show {
  border: 1px solid white;
}

.dropdown-type-chart-type .btn:focus {
  border: 1px solid white;
}

.dropdown-type-chart-type .btn {
  padding: 0px;
  font-weight: 700;
  font-size: .875rem;
  margin: .25rem .875rem;
}

.value-header {
  display: flex;
  align-items: center;
  margin: 0px;
  font-size: 2rem;
  font-weight: 900;
}

.diff-header {
  display: inline-block;
  font-size: 1.25rem;
  color: #D60A22;
  margin: 0px;
  margin-left: 5px;
  font-weight: 600;
}

.diff-header.plus {
  color: #2A6242;
}

.summary-card {
  width: 100%;
  margin: 0px;
  padding: 0px;
  margin-bottom: .3rem;
  border-bottom: 1px solid #e0e0e0;
}

.summary-card-last {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.summary-card.left {
  width: 90%;
}

.summary-card-title {
  font-size: .875rem;
  margin: 0px;
  padding: 0px;
}

.summary-card-detail {
  font-size: .875rem;
  font-weight: 700;
  padding: 0px;
  margin: 0px;
}

.rounded-button {
  border-radius: 20px;
}

.fontweight-700 {
  font-weight: 700;
}

.fontweight-900 {
  font-weight: 900;
}

.news-header {
  font-size: 1.7rem;
  font-weight: 900;
  margin: 0px;
  margin-bottom: 10px;
}

.news-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.news-date {
  /* color: #959595; */
  font-size: .85rem;
}

.news-title {
  font-size: 1rem;
  font-weight: 900;
  margin: 0px;
}

.news-category {
  margin-bottom: 1rem;
}

.news-category-item {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 5px;
  margin: 0px 1px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  color: #232A31;
}

.news-category-item.active {
  background-color: #5b636a;
  color: white;
}

.news-category-item:hover {
  color: #1967d2;
}

.news-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
  /* Match the chart height */
  font-size: 20px;
  color: #333;
}

.news-title:hover {
  color: #1967d2;
  cursor: pointer;
}

.news-content {
  font-size: .875rem;
}

.news-read-more {
  font-size: .875rem;
  font-weight: 700;
  color: #1967d2;
  cursor: pointer;
  white-space: 'pre-line';
}

.news-read-more:hover {
  text-decoration: underline;
}

.dropdown-type-summary {
  /* color: #1967d2; */
  border: 1px solid #e0e0e0;
  border-radius: 2rem;
  margin-bottom: .5rem;
  order: 1;
  /* Default order */
}

.dropdown-type-summary:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .08);
  /* color: #1967d2; */
}

.dropdown-type-summary:active {
  background-color: rgba(0, 0, 0, .08);
}

.dropdown-type-summary .btn.show {
  border: 1px solid white;
}

.dropdown-type-summary .btn:focus {
  border: 1px solid white;
}

.dropdown-type-summary .btn {
  padding: 0px;
  font-weight: 700;
  font-size: .875rem;
  margin: .25rem .875rem;
}

.dropdown-type-chart {
  color: #1967d2;
  margin: 0px 0px 6px 0px;
  font-size: 1rem;
  padding: 0px;
  border: 1px solid white;
}

.dropdown-type-chart:hover {
  color: #1967d2;
  /* margin: 0px; */
  font-size: 1rem;
  padding: 0px;
  border: 1px solid white;
}

.dropdown-type-chart.show {
  color: #1967d2;
  border: 1px solid white;
}

.dropdown-type-chart .btn.show {
  border: 1px solid white;
}

.dropdown-type-chart .btn:focus {
  border: 1px solid white;
}

.dropdown-type-chart .btn {
  padding: 0px;
  font-weight: 400;
  font-size: 1rem;
  margin: .25rem 0px;
}

.dropdown-date-chart {
  color: #1967d2;
  margin: 0px 0px 0px 1rem;
  font-size: 1rem;
  padding: 0px;
  border: 1px solid white;
}

.dropdown-date-chart:hover {
  color: #1967d2;
  margin: 0px;
  font-size: 1rem;
  padding: 0px;
  border: 1px solid white;
}

.dropdown-date-chart.show {
  color: #1967d2;
  border: 1px solid white;
}

.dropdown-date-chart .btn.show {
  border: 1px solid white;
}

.dropdown-date-chart .btn:focus {
  border: 1px solid white;
}

.dropdown-date-chart .btn {
  padding: 0px;
  font-weight: 400;
  font-size: 1rem;
  margin: .25rem 0px;
}

.dropdown-type-history {
  /* color: #1967d2; */
  border: 1px solid #e0e0e0;
  border-radius: 2rem;
  margin-bottom: .5rem;
  margin-right: .5rem;
  order: 1;
  /* Default order */
}

.dropdown-type-history:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .08);
  /* color: #1967d2; */
}

.dropdown-type-history:active {
  background-color: rgba(0, 0, 0, .08);
}

.dropdown-type-history .btn.show {
  border: 1px solid white;
}

.dropdown-type-history .btn:focus {
  border: 1px solid white;
}

.dropdown-type-history .btn {
  padding: 0px;
  font-weight: 700;
  font-size: .875rem;
  margin: .25rem .875rem;
}


.dropdown-news {
  /* color: #1967d2; */
  border: 1px solid #e0e0e0;
  border-radius: 2rem;
  margin-bottom: 4px;
  height: 38px;
  margin-right: .5rem;
  order: 1;
  /* Default order */
}

.dropdown-news:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .08);
  /* color: #1967d2; */
}

.dropdown-news:active {
  background-color: rgba(0, 0, 0, .08);
}

.dropdown-news .btn.show {
  border: 1px solid white;
}

.dropdown-news .btn:focus {
  border: 1px solid white;
}

.dropdown-news .btn:disabled {
  border: 1px solid white;
}

.dropdown-news .btn {
  padding: 0px;
  font-weight: 700;
  font-size: .875rem;
  margin: .25rem .875rem;
}

.header-tab {
  display: flex;
  padding: 0px;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 4px solid #e0e0e0;
  overflow-x: scroll;
}

.header-tab .title {
  font-size: 1rem;
  font-weight: 900;
  margin: 0px;
  padding: 10px 10px;
  cursor: pointer;
  min-width: fit-content;
}

.header-tab .title:hover {
  /* background-color: #e7f5ff; */
  color: #1967d2;
  margin-bottom: -4px;
  border-bottom: 4px solid #1967d2;
}

.header-tab .title.active {
  /* background-color: #e7f5ff; */
  color: #1967d2;
  margin-bottom: -4px;
  border-bottom: 4px solid #1967d2;
}

.header-tab-2 {
  display: flex;
  padding: 0px;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 10px;
  /* border-bottom: 4px solid #e0e0e0; */
}

.header-tab-2 .title {
  font-size: 1rem;
  font-weight: 900;
  margin: 0px;
  padding: 10px 10px;
  cursor: pointer;
}

.header-tab-2 .title.active:hover {
  background-color: #e7f5ff;
  color: #1967d2;
  /* margin-bottom: -4px;
  border-bottom: 4px solid #1967d2; */
}

.header-tab-2 .title.active {
  /* background-color: #e7f5ff; */
  color: #1967d2;
  margin-bottom: -4px;
  border-bottom: 4px solid #1967d2;
}

.content-tab {
  border-bottom: 2px solid #e0e0e0;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.download-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.download-tab:hover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  color: #1967d2;
}

.download-lock-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}


.chart-time {
  margin: 0px;
  padding: 0px;
  display: flex;
  /* Add flex display to align items on the same line */
  justify-content: space-between;
  /* Space items evenly */
  align-items: center;
  /* Align items vertically in the center */
}

.date-range-active {
  background-color: #5b636a;
  color: white;
}

.date-range-dropdown {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 5px;
  margin: 0px 1px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  color: #232A31;
}

.date-range-title {
  display: inline;
  font-weight: 700;
  font-size: 14px;
}

.date-range-dropdown.active {
  background-color: #5b636a;
  color: white;
}


.date-range-dropdown:hover {
  color: #1967d2;
  /* background-color: #5b636a; */
  /* color: white; */
}

.date-range-dropdown-calendar {
  display: inline-block;
  /* padding: 5px 5px; */
  border-radius: 5px;
  margin: 0px 15px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  color: #232A31;
}

.compare-radio-button.btn {
  padding: 3px 6px;
  margin-bottom: 0px;
}

.reset-button-home {
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 7px;
}

.reset-button-home:hover {
  color: #1967d2;
}

.compare-div-mini-yoy {
  max-width: 17.6rem;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.compare-radio-button-yoy.btn {
  padding: 2px 13px;
  width: 4.08rem;
  margin: 0px 2px 10px 2px;
}

.compare-button-yoy {
  width: 100%;
  margin-top: 0px
}

.select-calendar {
  background-color: transparent;
  border: none;
}

.select-calendar-arrow {
  background-color: transparent;
  border: none;
  font-weight: 900;
  font-size: 15px;

}

.dropdown-type-table-div {
  /* width: 10px; */

}

.dropdown-table-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 0px 0px;

}

.dropdown-table-button.btn {
  padding: 5px 10px;
  margin: 0px 4px;

}

.location-cancel-button {
  cursor: pointer;
  margin-left: 10px;
}

.location-cancel-button:hover {
  color: red;
}

.info-button {
  cursor: pointer;
  margin-right: 10px;
  display: inline;
  padding-bottom: 10px;
  padding-top: 10px;
}

.info-button:hover {
  /* color: red; */
}

.location-cancel-div:hover {
  background-color: #f0f0f0;
  /* Change background color to your desired color */
}

.location-cancel-div {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin: 10px 0px 0px 0px;
  transition: background-color 0.3s;
  /* Smooth transition */
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 285px;
  /* Match the chart height */
  font-size: 20px;
  color: #333;
}

.loading-indicator-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 430px;
  /* Match the chart height */
  font-size: 20px;
  color: #333;
}

.dropdown-date-picker {
  border-radius: 20px;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-color: #e0e0e0;
  display: inline-block;
  padding: 2px 5px;
  margin: 0px 1px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  color: #232A31;
}

.table-date-range-dropdown {}

.leaflet-container {
  width: 100wh;
  height: 30vh;
}

.map-container {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e0e0e0;

}

.search-button.btn {
  height: 38px;
  margin-left: -20px;
}

.select-country .select__control {
  border-radius: .375rem 0px 0px .375rem;
}

.search-bar {
  width: 400px;
}

@media (max-width: 767px) {
  .search-bar {
    width: 100%;
  }
}

.new-detail-page {
  padding: 20px;
  max-width: 800px;
}

.news-detail-title {
  color: #232a31;
  font-size: 38px;
  font-style: normal;
  font-weight: 900;
  line-height: 115%;
  letter-spacing: -.36px;
}

.news-detail-author {
  color: #232a31;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -.36px;
}

.news-detail-datetime {
  color: #232a31;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -.36px;
}

.news-detail-author-container {
  /* display: flex; */
  /* justify-content: space-between; */
  margin-top: 10px;
  border-top: #1967d2 1px solid;
  border-bottom: #1967d2 1px solid;
  padding: .25rem 1rem;
  margin: 1rem 0;
}

.news-detail-content {
  color: #232a31;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -.36px;
  white-space: pre-line;
}

.news-detail-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.report-system-a {
  color: black;
  font-size: 13px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 150%;
  letter-spacing: -.36px;
  /* text-decoration: none; */

}

.reference {
  font-size: 11px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 10px;
  letter-spacing: -.36px;
  text-align: right;
  margin-bottom: 10px;
  margin-right: 5px;
}

.reference-footer {
  font-size: 12px;
  font-style: normal;
  /* font-weight: 700; */
  /* line-height: 10px;
  letter-spacing: -.36px; */
}

.calender {
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin: 0px;
  max-width: 280px;
  text-align: right;
}

.reference-container {
  flex: 1;
  /* Take up remaining space */
  display: flex;
  justify-content: flex-end;
  /* Align to the right */
  align-items: center;
  /* Align vertically */
}

.reference-table {
  font-size: 11px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 10px;
  letter-spacing: -.36px;
  text-align: right;
  margin-top: 10px;
  margin-bottom: 0px;
}

.news-more {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -.36px;
  color: #1967d2;
  cursor: pointer;
  margin-top: 10px;
}

.news-more:hover {
  text-decoration: underline;
}

.news-more-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.news-more-container:hover {
  background-color: #f0f0f0;
  /* Change background color to your desired color */
}

.news-more-container:hover .news-more {
  color: #1967d2;
}

.news-more-container:hover .news-more:hover {
  text-decoration: underline;
}

.signout {
  /* color: #1967d2; */
  cursor: pointer;
  font-size: 25px;
  margin: 0px 10px;
}

.keyword-search {
  max-width: 400px;
  display: inline-block;
}


.climate-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* Match the chart height */
  font-size: 20px;
  color: #333;
}

.climate-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* Match the chart height */
  font-size: 20px;
  color: #333;
}

.climate-log {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* Match the chart height */
  font-size: 20px;
  color: #333;
}

.fa-trash {
  color: red;
}

.fa-trash:hover {
  color: #FF6969;
}