.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
    background: white;
    padding: 1rem 1rem;
    border-radius: 8px;
    width: 600px;
    max-width: 100%;
    max-height: 100vh;
  }

  .article-name {
    height: auto;
    width: auto;
    overflow-x: auto;
  }

  .chapter-container {
    overflow-y: scroll;
    border: 1px solid rgba(48, 57, 105, 0.15);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }

  .progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .progress-bar {
    background: #e0e0e0;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 10px;
  }

  .progress-bar-fill {
    background: #76c7c0;
    height: 100%;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    padding: 5px 0;
  }

  .completed {
    color: green;
    font-weight: bold;
  }


  h3 {
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: color 0.3s ease;
  }

  h3:hover {
    background-color: rgb(240, 240, 240);
  }

  .button-container {
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 1rem;
  }

  .button-container button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .close-button {
    background: #76c7c0;
    color: white;
    margin: 0;
  }

  .reset-button {
    background: #f44336;
    color: white;
    margin: 0;
  }
}