/* General Footer Styling */
.footer-container {
    display: flex;
    max-width: 94%;
}

.modal-content {
    background: white;
    padding: 1rem 1rem;
    border-radius: 8px;
    width: 100%;
    max-width: 100%;
    max-height: 90vh;
    /* maxHeight: "400px"; */
    overflow: auto;
}