.subscription-plan {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.plans {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.plan {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    width: 250px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    transition: all 0.3s ease;
}

.plan:hover {
    transform: translateY(-5px);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
}

.plan h2 {
    margin-top: 0;
}

.plan p {
    font-size: 20px;
    margin: 10px 0;
}

.plan ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
}

.plan ul li {
    margin-bottom: 5px;
}

.plan button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.plan button:hover {
    background-color: #0056b3;
}

.already-have-account {
    /* margin-top: 20px; */
    text-align: center; /* Center the text horizontally */
    margin:auto;
    align-items: center;
}

/* SignOutButton.css */

.sign-out-button {
    position: absolute;
    top: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    cursor: pointer;
    /* background-color: #007bff;
    color: #fff; */
    padding: 10px 15px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
  }
  
  .sign-out-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  

.back-button {
    width: fit-content;
    /* position: absolute; */
    /* top: 20px;
    left: 20px; */
    cursor: pointer;
    /* background-color: #007bff; */
    /* color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s; */
  }
  
  .back-button:hover {
    color: gray;
  }
  .login-container {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 2rem;
    padding: 20px 0px;
    
    /* text-align: center; */
  }